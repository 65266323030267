
























































































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import stores from "@/store/store";
const store: any = stores;
import Axios from "axios";
import { HollowDotsSpinner } from "epic-spinners";
import { ADDRESS_UNIT_DESIGNATOR_TYPE } from "@/constants";

@Component({ components: { HollowDotsSpinner } })
export default class LosAddressComponent extends Vue {
  public stateCountyLoader = false;
  public states: any = [];
  public counties: any = [];
  public addressUnitDesignatorType = ADDRESS_UNIT_DESIGNATOR_TYPE;

  public get stateList() {
    return store.state.sessionObject.states || [];
  }
 
  @Prop()
  public name;
  @Prop()
  public showCounty;

  @Prop({default: false})
  public useUnitType: boolean;

  @Prop({default: false})
  public loanIsLocked: boolean;

  @Prop()
  public address;

  @Prop()
  public required;

  @Prop()
  public streetField;

  @Prop()
  public disableAddress;
  @Prop()
  public isSubmitToWemlo;
  @Prop()
  public highLightLosRequiredField;
  public async getCityAndState(mountCall) {
    try {
      let zip = this.address.zip;
      let zipValidate = await this.$validator.validate(this.name, zip);
      if (this.address.zip == "" || !zipValidate) {
        return;
      }
      this.stateCountyLoader = true;
      let findState: any = null;
      let findCountry: any = null;
      let findCity: any = null;
      let findCounty: any = null;
      let response = await Axios.get(
        "https://maps.googleapis.com/maps/api/geocode/json?sensor=true&key=AIzaSyC4z_vM_4vNFs5_KH5wUdTjJrP8yLS9lr8&components=postal_code:" +
          zip,
          { headers: { public: true}}
      );
      if (response.status == 200 && response.data.status == "OK") {
        response.data.results[0]["address_components"].forEach(d => {
          if (d.types[0] == "administrative_area_level_1") {
            findState = d;
          }
          if (d.types[0] == "country") {
            findCountry = d.long_name;
          }
          if (d.types[0] == "administrative_area_level_2") {
            findCounty = d.long_name;
          }
          if (d.types[0] == "locality") {
            findCity = d.long_name;
          } else {
            if (d.types[0] == "administrative_area_level_3")
              findCity = d.long_name;
          }
        });

        if (findCity && !mountCall) {
          this.address.city = findCity;
        }
        if (findCounty && !mountCall) {
          this.address.county = findCounty;
        }
        if (findCountry && !mountCall) {
          this.address.country = findCountry;
        }
        if (findState) {
          this.address.state = null;
          this.states = [];
          this.address.state = {
            name: findState.long_name,
            code: findState.short_name
          };
          this.states = [
            {
              state: findState.long_name,
              code: findState.short_name
            }
          ];
        } else {
          this.setStates(mountCall);
        }
      } else {
        this.setStates(mountCall);
      }
      this.stateCountyLoader = false;
    } catch (error) {
      this.stateCountyLoader = false;
    }
  }
  public async setStates(mountCall) {
    if (!mountCall) {
      this.states = [];
      this.address.state = null;
      this.address.city = null;
      this.address.country = null;
      this.address.county = null;
    }
    this.stateList.forEach(data => {
      this.states.push(data);
    });
  }

  fetchCounties() {
    if (this.address.zip) {
      let address: any = this.address.state && this.stateList && this.stateList.length > 0
        ? this.stateList.filter(a => a.code == this.address.state.code)
        : [];
      return address.length > 0 ? address[0].counties : [];
    }else{
      this.address.city = null;
      this.address.state = null;
      this.address.county = null;
      this.address.zip = null;
    }
   
  }
  clearData(){
    if(!this.address.zip){
      this.address.state = null;
      this.address.city = null;
      this.address.county = null;
      this.address.zip = null;
      this.states = [];
    }
  }

  async mounted() {
    if (this.address.zip) {
      await this.getCityAndState(true);
    }
  }
  @Watch("address.zip")
  calc2(n) {
    this.getCityAndState(false);
  }
}
