import { Address } from './pos/common.model';

export class borrowerInfo {
    constructor() {
        this.loanTxnId = "",
        this.borrowerInfo = new borrowerInfoSchema();
    }
    loanTxnId: string;
    borrowerInfo: borrowerInfoSchema;
    // coBorrowerInfo : []
}

class borrowerInfoSchema {
    constructor() {
        this.personalInfo = new personalInfo();
        //this.currentEmployment = {};
        //this.previousEmployment = {};
        //this.incomeFromPreviousSource = {};
    }
    personalInfo: personalInfo
    //currentEmployment: object;
    //previousEmployment: object;
    //incomeFromPreviousSource: object;
}

class personalInfo {
    constructor() {
        this.name = new name();
        this.contactInformation = new contactInfo();
        this.address = new address();
    }
    name: name;
    contactInformation: contactInfo
    address: address
}
class name {
    constructor() {
        this.firstName = "",
        this.middleName = "",
        this.lastName = ""
        this.nameSuffix = "";
    }
    firstName: string;
    middleName: string;
    lastName: string;
    nameSuffix: string;
}
class contactInfo {
    constructor() {
        this.cellPhone = "",
        this.email = ""
    }
    cellPhone: string;
    email: string;
}
class address {
    constructor() {
        this.currentAddress = new addressFormat();
        this.formerAddress = new addressFormat();
    }
    currentAddress: addressFormat;
    formerAddress: addressFormat;
}
class addressFormat{
    constructor(){
        this.address= new Address;
        this.duration = {};
        this.housing = {};
    }
    address:Address;
    duration:object;
    housing:object;
}

export class Income {
    constructor(type, value){
        this.type = type;
        this.value = value;
    }
    type:string;
    value:number;
}