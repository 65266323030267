


import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import QuestionnaireMixin from "@/mixins/questionnaire.mixin";
import { mixins } from "vue-class-component";
import AddressComponent from "@/views/LosAddress.vue";
import Axios from "axios";
import { BASE_API_URL } from "@/config";

import { PreviousEmployment } from "@/models/pos/borrower-info.model";
import {
  MISMO_INCOME_SOURCE,
  SELF_EMPLOYED_STATUS
} from "@/constants";
import { Income } from "@/models/borrowerInfo.model";
import { Address } from "@/models/pos/common.model";
import _ from "lodash";
@Component({ components: { AddressComponent } })
export default class IncomeInformationComponent extends mixins(
  QuestionnaireMixin
) {
  @Prop()
  public history;
  public selfEmployedType = SELF_EMPLOYED_STATUS;
  public dotLoader = false;
  public incomeDetail = null;
  public incomes = [];
  public showIncomeMessage: boolean = false;

  public get requiredValidationOnYearOrMonths() {
    let requiredValidation = false;
    if (this.incomeDetail) {
      let years = this.incomeDetail.borrowerInfo[this.questionHistory.index]
        .currentEmployment[0].workingPeriod.years;
      let months = this.incomeDetail.borrowerInfo[this.questionHistory.index]
        .currentEmployment[0].workingPeriod.months;
      requiredValidation = years || months ? false : true;
    }
    return requiredValidation;
  }

  public async getIncomeDetail() {
    try {
      this.dotLoader = true;
      let res = await Axios.get(BASE_API_URL + "pos/getIncomeDetail",{
        params: {
          loanTxnId: this.$route.query.loanTxnId
        }
      });
      this.incomeDetail = res.data;
      this.dotLoader = false;
    } catch (error) {
      this.dotLoader = false;
    }
  }
  public async saveIncomeDetail(nextQuestion?: number, currentQuestion?: number, scope?: string, nextSection?: string, userType?:string, index?:number) {
    try {
      this.$store.state.wemloLoader = true;
      let childrensValid = await this.validateChildrenComponentForms(this.$children);
      let valid = await this.$validator.validateAll(scope);
      if (!valid || !childrensValid || !this.validateIncomeItens()) {
        this.$store.state.wemloLoader = false;
        return;
      }
      await this.removeIncomeItens();
      let res = await Axios.post(
        BASE_API_URL + "pos/saveIncomeDetail",
        {
          borrowerInfo: this.incomeDetail.borrowerInfo,
          loanTxnId: this.$route.query.loanTxnId
        });
      this.$store.state.wemloLoader = false;
      this.$snotify.clear();
      this.onNext(nextQuestion, currentQuestion, scope, nextSection, userType, index);
      this.$snotify.success("Information saved successfully", {
        timeout: 1000
      });
    } catch (error) {
      this.$store.state.wemloLoader = false;
      console.log(error);
      this.$snotify.error(
        "We are unable to save your details. Please try contacting the administrator."
      );
    }
  }

  public validateIncomeItens() {
    let isValid = true;
    _.get(this.incomeDetail.borrowerInfo, 'currentEmployment', []).forEach(employment => {
      if(isValid && !employment.isRetired) {
        isValid = employment.grossMonthlyIncome.base.monthlyIncome > 0
        || employment.grossMonthlyIncome.overtime.monthlyIncome > 0
        || employment.grossMonthlyIncome.bonus.monthlyIncome > 0
        || employment.grossMonthlyIncome.commission.monthlyIncome > 0
        || employment.grossMonthlyIncome.dividends.monthlyIncome > 0
        || employment.grossMonthlyIncome.netRentalIncome.monthlyIncome > 0
        || employment.grossMonthlyIncome.other.monthlyIncome > 0
        || employment.grossMonthlyIncome.contractBasis.monthlyIncome > 0
        || employment.grossMonthlyIncome.militaryBasePay.monthlyIncome > 0
        || employment.grossMonthlyIncome.militaryClothesAllowance.monthlyIncome > 0
        || employment.grossMonthlyIncome.militaryCombatPay.monthlyIncome > 0
        || employment.grossMonthlyIncome.militaryFlightPay.monthlyIncome > 0
        || employment.grossMonthlyIncome.militaryHazardPay.monthlyIncome > 0
        || employment.grossMonthlyIncome.militaryOverseasPay.monthlyIncome > 0
        || employment.grossMonthlyIncome.militaryPropPay.monthlyIncome > 0
        || employment.grossMonthlyIncome.militaryQuartersAllowance.monthlyIncome > 0
        || employment.grossMonthlyIncome.militaryRationsAllowance.monthlyIncome > 0
        || employment.grossMonthlyIncome.militaryVariableHousingAllowance.monthlyIncome > 0
        || employment.selfEmployedIndicator.monthlyIncome > 0;
      }
    });
    return isValid;
  }

  public async removeIncomeItens() {
    _.get(this.incomeDetail.borrowerInfo, 'currentEmployment', []).forEach(employment => {
      if(employment.selfEmployedIndicator.isSelfEmployed) {
        employment.grossMonthlyIncome.base.monthlyIncome = null;
        employment.grossMonthlyIncome.overtime.monthlyIncome = null;
        employment.grossMonthlyIncome.bonus.monthlyIncome = null;
        employment.grossMonthlyIncome.commission.monthlyIncome = null;
        employment.grossMonthlyIncome.dividends.monthlyIncome = null;
        employment.grossMonthlyIncome.netRentalIncome.monthlyIncome = null;
        employment.grossMonthlyIncome.other.monthlyIncome = null;
        employment.grossMonthlyIncome.contractBasis.monthlyIncome = null;
        employment.grossMonthlyIncome.militaryBasePay.monthlyIncome = null;
        employment.grossMonthlyIncome.militaryClothesAllowance.monthlyIncome = null;
        employment.grossMonthlyIncome.militaryCombatPay.monthlyIncome = null;
        employment.grossMonthlyIncome.militaryFlightPay.monthlyIncome = null;
        employment.grossMonthlyIncome.militaryHazardPay.monthlyIncome = null;
        employment.grossMonthlyIncome.militaryOverseasPay.monthlyIncome = null;
        employment.grossMonthlyIncome.militaryPropPay.monthlyIncome = null;
        employment.grossMonthlyIncome.militaryQuartersAllowance.monthlyIncome = null;
        employment.grossMonthlyIncome.militaryRationsAllowance.monthlyIncome = null;
        employment.grossMonthlyIncome.militaryVariableHousingAllowance.monthlyIncome = null;
      } else if(employment.militaryIndicator) {
        employment.grossMonthlyIncome.base.monthlyIncome = null;
        employment.grossMonthlyIncome.overtime.monthlyIncome = null;
        employment.grossMonthlyIncome.bonus.monthlyIncome = null;
        employment.grossMonthlyIncome.commission.monthlyIncome = null;
        employment.grossMonthlyIncome.dividends.monthlyIncome = null;
        employment.grossMonthlyIncome.netRentalIncome.monthlyIncome = null;
        employment.grossMonthlyIncome.other.monthlyIncome = null;
        employment.grossMonthlyIncome.contractBasis.monthlyIncome = null;
        employment.selfEmployedIndicator.monthlyIncome = null;
      } else {
        employment.grossMonthlyIncome.militaryBasePay.monthlyIncome = null;
        employment.grossMonthlyIncome.militaryClothesAllowance.monthlyIncome = null;
        employment.grossMonthlyIncome.militaryCombatPay.monthlyIncome = null;
        employment.grossMonthlyIncome.militaryFlightPay.monthlyIncome = null;
        employment.grossMonthlyIncome.militaryHazardPay.monthlyIncome = null;
        employment.grossMonthlyIncome.militaryOverseasPay.monthlyIncome = null;
        employment.grossMonthlyIncome.militaryPropPay.monthlyIncome = null;
        employment.grossMonthlyIncome.militaryQuartersAllowance.monthlyIncome = null;
        employment.grossMonthlyIncome.militaryRationsAllowance.monthlyIncome = null;
        employment.grossMonthlyIncome.militaryVariableHousingAllowance.monthlyIncome = null;
        employment.selfEmployedIndicator.monthlyIncome = null;
      }
    });
  }

  public get haveRetired(){
    if(this.incomeDetail)
    return this.incomeDetail.borrowerInfo[this.questionHistory.index].currentEmployment[0].isRetired;
    return false;
  }

  /**
   * An asynchronous method to re-initialise fields of type string/object/array.
   * @param key Data property which is to be re-initialised.
   */
  public async clearFilledData(key: string) {
    const i = this.questionHistory.index;
    switch (key) {
      case "WorkingPeriod":
        if (this.isLessThanTwoYears(this.incomeDetail.borrowerInfo[i]))
          return;
        this.incomeDetail.borrowerInfo[i].previousEmployment = [
          new PreviousEmployment(),
          new PreviousEmployment()
        ];
        break;
    }
  }

  removeCurrentEmploymentData(){
    if(this.incomeDetail.borrowerInfo[this.questionHistory.index].currentEmployment[0].isRetired){
      this.incomeDetail.borrowerInfo[this.questionHistory.index].currentEmployment[0].employerName = null;
      this.incomeDetail.borrowerInfo[this.questionHistory.index].currentEmployment[0].address = new Address();
      this.incomeDetail.borrowerInfo[this.questionHistory.index].currentEmployment[0].phone = null;
      this.incomeDetail.borrowerInfo[this.questionHistory.index].currentEmployment[0].industry = null;
      this.incomeDetail.borrowerInfo[this.questionHistory.index].currentEmployment[0].selfEmployedIndicator.isSelfEmployed = false;
      this.incomeDetail.borrowerInfo[this.questionHistory.index].currentEmployment[0].startDate.month = null;
      this.incomeDetail.borrowerInfo[this.questionHistory.index].currentEmployment[0].startDate.year = null;
      this.incomeDetail.borrowerInfo[this.questionHistory.index].currentEmployment[0].position = null;
      this.incomeDetail.borrowerInfo[this.questionHistory.index].currentEmployment[0].workingPeriod.years = null;
    }
  }

  public addIncome(isRetired) {
    if(!isRetired) {
      this.incomes.push(new Income(null,0));
    }
  }

  public changeIncomeValue(index, value) {
    if (this.incomes[index].type) {
      this.incomeDetail.borrowerInfo[this.questionHistory.index].currentEmployment[0].grossMonthlyIncome[this.incomes[index].type].monthlyIncome = value;
    }
  }

  @Watch('incomes')
  public onChangeIncomes() {
    this.validateIncomeValues();
  }

  public validateIncomeValues() {
    this.showIncomeMessage = false;
    if (_.get(this.incomeDetail.borrowerInfo[this.questionHistory.index].currentEmployment[0], 'selfEmployedIndicator.isSelfEmployed') && _.get(this.incomeDetail.borrowerInfo[this.questionHistory.index].currentEmployment[0], 'selfEmployedIndicator.monthlyIncome') <= 0) {
      this.showIncomeMessage = true;
    } else if(!_.get(this.incomeDetail.borrowerInfo[this.questionHistory.index].currentEmployment[0], 'selfEmployedIndicator.isSelfEmployed')) {
      const incomesAux = this.incomes.filter(income => income.value > 0);
      if (incomesAux.length == 0) {
        this.showIncomeMessage = true;
      }
    }  
    if(_.get(this.incomeDetail.borrowerInfo[this.questionHistory.index].currentEmployment[0], 'isRetired')) {
      this.showIncomeMessage = false;
    }
  }

  public removeIncomeFromList(index, splice) {
    if (this.incomes[index].type) {
      this.incomeDetail.borrowerInfo[this.questionHistory.index].currentEmployment[0].grossMonthlyIncome[this.incomes[index].type].monthlyIncome = 0;
    }
    this.incomes.splice(index, splice);
  }

  public removeAllIncomesFromList() {
      this.incomes.forEach(income => {
        this.incomeDetail.borrowerInfo[this.questionHistory.index].currentEmployment[0].grossMonthlyIncome[income.type].monthlyIncome = 0;
      });
      this.incomes = [];
  }

  public getIncomeType() {
    return MISMO_INCOME_SOURCE.filter(income => income.isMilitary == !!this.incomeDetail.borrowerInfo[this.questionHistory.index].currentEmployment[0].militaryIndicator).map(income => {
      if(this.incomes.find(inc => inc.type == income.value)) {
        income.disabled = true;
      } else {
        income.disabled = false;
      }
      return income;
    });
  }

  @Watch('incomeDetail.borrowerInfo[this.questionHistory.index].currentEmployment[0]')
  async onChangeCurrenIndex() {
    await this.generateListFromGrossMonthlyObject();
  }

  async generateListFromGrossMonthlyObject() {
    this.incomes = [];
    if(!this.incomeDetail.borrowerInfo[this.questionHistory.index].currentEmployment[0].militaryIndicator) {
      if(this.incomeDetail.borrowerInfo[this.questionHistory.index].currentEmployment[0].grossMonthlyIncome.base.monthlyIncome > 0)
        this.incomes.push(new Income('base', this.incomeDetail.borrowerInfo[this.questionHistory.index].currentEmployment[0].grossMonthlyIncome.base.monthlyIncome));

      if(this.incomeDetail.borrowerInfo[this.questionHistory.index].currentEmployment[0].grossMonthlyIncome.bonus.monthlyIncome > 0)
        this.incomes.push(new Income('bonus', this.incomeDetail.borrowerInfo[this.questionHistory.index].currentEmployment[0].grossMonthlyIncome.bonus.monthlyIncome));

      if(this.incomeDetail.borrowerInfo[this.questionHistory.index].currentEmployment[0].grossMonthlyIncome.commission.monthlyIncome > 0)
        this.incomes.push(new Income('commission', this.incomeDetail.borrowerInfo[this.questionHistory.index].currentEmployment[0].grossMonthlyIncome.commission.monthlyIncome));

      if(this.incomeDetail.borrowerInfo[this.questionHistory.index].currentEmployment[0].grossMonthlyIncome.contractBasis.monthlyIncome > 0)
        this.incomes.push(new Income('contractBasis', this.incomeDetail.borrowerInfo[this.questionHistory.index].currentEmployment[0].grossMonthlyIncome.contractBasis.monthlyIncome));

      if(this.incomeDetail.borrowerInfo[this.questionHistory.index].currentEmployment[0].grossMonthlyIncome.overtime.monthlyIncome > 0)
        this.incomes.push(new Income('overtime', this.incomeDetail.borrowerInfo[this.questionHistory.index].currentEmployment[0].grossMonthlyIncome.overtime.monthlyIncome));

      if(this.incomeDetail.borrowerInfo[this.questionHistory.index].currentEmployment[0].grossMonthlyIncome.other.monthlyIncome > 0)
        this.incomes.push(new Income('other', this.incomeDetail.borrowerInfo[this.questionHistory.index].currentEmployment[0].grossMonthlyIncome.other.monthlyIncome));
    } else {
      if(this.incomeDetail.borrowerInfo[this.questionHistory.index].currentEmployment[0].grossMonthlyIncome.militaryBasePay.monthlyIncome > 0)
        this.incomes.push(new Income('militaryBasePay', this.incomeDetail.borrowerInfo[this.questionHistory.index].currentEmployment[0].grossMonthlyIncome.militaryBasePay.monthlyIncome));
      
      if(this.incomeDetail.borrowerInfo[this.questionHistory.index].currentEmployment[0].grossMonthlyIncome.militaryClothesAllowance.monthlyIncome > 0)
        this.incomes.push(new Income('militaryClothesAllowance', this.incomeDetail.borrowerInfo[this.questionHistory.index].currentEmployment[0].grossMonthlyIncome.militaryClothesAllowance.monthlyIncome));

      if(this.incomeDetail.borrowerInfo[this.questionHistory.index].currentEmployment[0].grossMonthlyIncome.militaryCombatPay.monthlyIncome > 0)
        this.incomes.push(new Income('militaryCombatPay', this.incomeDetail.borrowerInfo[this.questionHistory.index].currentEmployment[0].grossMonthlyIncome.militaryCombatPay.monthlyIncome));

      if(this.incomeDetail.borrowerInfo[this.questionHistory.index].currentEmployment[0].grossMonthlyIncome.militaryFlightPay.monthlyIncome > 0)
        this.incomes.push(new Income('militaryFlightPay', this.incomeDetail.borrowerInfo[this.questionHistory.index].currentEmployment[0].grossMonthlyIncome.militaryFlightPay.monthlyIncome));

      if(this.incomeDetail.borrowerInfo[this.questionHistory.index].currentEmployment[0].grossMonthlyIncome.militaryHazardPay.monthlyIncome > 0)
        this.incomes.push(new Income('militaryHazardPay', this.incomeDetail.borrowerInfo[this.questionHistory.index].currentEmployment[0].grossMonthlyIncome.militaryHazardPay.monthlyIncome));

      if(this.incomeDetail.borrowerInfo[this.questionHistory.index].currentEmployment[0].grossMonthlyIncome.militaryOverseasPay.monthlyIncome > 0)
        this.incomes.push(new Income('militaryOverseasPay', this.incomeDetail.borrowerInfo[this.questionHistory.index].currentEmployment[0].grossMonthlyIncome.militaryOverseasPay.monthlyIncome));

      if(this.incomeDetail.borrowerInfo[this.questionHistory.index].currentEmployment[0].grossMonthlyIncome.militaryPropPay.monthlyIncome > 0)
        this.incomes.push(new Income('militaryPropPay', this.incomeDetail.borrowerInfo[this.questionHistory.index].currentEmployment[0].grossMonthlyIncome.militaryPropPay.monthlyIncome));

      if(this.incomeDetail.borrowerInfo[this.questionHistory.index].currentEmployment[0].grossMonthlyIncome.militaryQuartersAllowance.monthlyIncome > 0)
        this.incomes.push(new Income('militaryQuartersAllowance', this.incomeDetail.borrowerInfo[this.questionHistory.index].currentEmployment[0].grossMonthlyIncome.militaryQuartersAllowance.monthlyIncome));

      if(this.incomeDetail.borrowerInfo[this.questionHistory.index].currentEmployment[0].grossMonthlyIncome.militaryRationsAllowance.monthlyIncome > 0)
        this.incomes.push(new Income('militaryRationsAllowance', this.incomeDetail.borrowerInfo[this.questionHistory.index].currentEmployment[0].grossMonthlyIncome.militaryRationsAllowance.monthlyIncome));

      if(this.incomeDetail.borrowerInfo[this.questionHistory.index].currentEmployment[0].grossMonthlyIncome.militaryVariableHousingAllowance.monthlyIncome > 0)
        this.incomes.push(new Income('militaryVariableHousingAllowance', this.incomeDetail.borrowerInfo[this.questionHistory.index].currentEmployment[0].grossMonthlyIncome.militaryVariableHousingAllowance.monthlyIncome));
    }
  }

  public disableEmploymentFields(){
    if(this.incomeDetail.borrowerInfo[this.questionHistory.index].currentEmployment[0].isRetired){
      this.incomeDetail.borrowerInfo[this.questionHistory.index].currentEmployment[0].employerName = null;
      this.incomeDetail.borrowerInfo[this.questionHistory.index].currentEmployment[0].address = new Address();
      this.incomeDetail.borrowerInfo[this.questionHistory.index].currentEmployment[0].phone = null;
      this.incomeDetail.borrowerInfo[this.questionHistory.index].currentEmployment[0].industry = null;
      this.incomeDetail.borrowerInfo[this.questionHistory.index].currentEmployment[0].selfEmployedIndicator.isSelfEmployed = false;
      this.incomeDetail.borrowerInfo[this.questionHistory.index].currentEmployment[0].selfEmployedIndicator.selfEmployedType = null;
      this.incomeDetail.borrowerInfo[this.questionHistory.index].currentEmployment[0].startDate.month = null;
      this.incomeDetail.borrowerInfo[this.questionHistory.index].currentEmployment[0].startDate.year = null;
      this.incomeDetail.borrowerInfo[this.questionHistory.index].currentEmployment[0].position = null;
      this.incomeDetail.borrowerInfo[this.questionHistory.index].currentEmployment[0].workingPeriod.years = null;
      this.removeAllIncomesFromList();
    }
  }

  async mounted() {
    this.questionHistory = this.history;
    await this.getIncomeDetail();
    await this.generateListFromGrossMonthlyObject();
  }
}
